import React from 'react';
import { BoutonHaxio } from '../../components/ui/Boutons.component';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { theme } from '../../theme/theme';

export function ListeHaxio(props) {
	const useStylesList = makeStyles((themeList) => ({
		root: {
			width: '100%',
			margin: '0',
			padding: '0',
		},
	}));

	const classesListe = useStylesList();
	const { tableau, primary, secondary, secondary2, secondary3, action, id, type } = props;
	return (
		<Grid container direction="row" justify="center" alignItems="center">
			<Grid item xs={12} md={12}>
				<ul className={classesListe.root}>
					{tableau.map((element, index) => {
						return (
							<ListeItemHaxio
								key={element[id]}
								libelle={element[primary].label || element[primary]}
								progression={element[secondary]}
								date={element[secondary2].slice(0, 10)}
								client={element[primary][secondary3].label}
								action={() => {
									// send questionnaireId,QuestionnaireHasUserId, nextQuestion
									action(element[id], element.id, element.nextQuestion,element.feuilleRoute);
								}}
								index={index}
								type={type}
							/>
						);
					})}
				</ul>
			</Grid>
		</Grid>
	);
}

export function ListeItemHaxio(props) {
	const { date, libelle, action, index, client, progression, type} = props;
	const useStylesListItem = makeStyles((themeListItem) => ({
		root: {
			backgroundColor: index % 2 === 0 ? 'white' : 'transparent',
			listStyle: 'none',
			marginBottom: '5px',
			padding: '0.5rem 0.5rem 0.5rem 2rem ',
			lineHeight: '2rem',
			// textAlign: 'center',
		},
		label: {
			paddingRight: '1%',
		},
	}));

	const classesListeItem = useStylesListItem();
	return (
		<Grid container>
			<Grid item xs={12} md={12}>
				<li className={classesListeItem.root}>
					<Grid container>
						<Grid item xs={9} md={9} style={theme.typography} className={classesListeItem.label}>
						{client} - {libelle} - {date} {type === 'listeEncours' ? '- ' + progression + '%' : null}
						</Grid>

						<Grid item xs={3} md={3}>
							<BoutonHaxio libelle="Répondre" couleur="primary" action={action} small="true" />
						</Grid>
					</Grid>
				</li>
			</Grid>
		</Grid>
	);
}
