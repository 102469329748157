import { doGet } from './WrapperProtectedAxiosReq.service';
const API_URL = process.env.REACT_APP_API_URL + 'texts/';

export async function donnees(page) {
	try {
		let req = await doGet(API_URL + 'page?page=' + page);
		return req.data;
	} catch (error) {
		return error;
	}
}

// This iterate over the json file and return a array of all the html element
export const generateBody = (content) => {
	let body = ""
	content.body.map(item => {
		console.log(item.content)
		switch (item.type) {
			case 'h2':
				body = body + '<h2>' + item.content + '</h2>'
				break;
			case 'h3':
				body = body + '<h3>' + item.content + '</h3>'
				break;
			case 'p':
				body = body + '<p>' + item.content + '</p>'
				break;
			case 'li':
				body = body + generateList(item.content)
				break;
			default:
				console.log("The JSON for RGPD is not formatted correctly in here : " + item.content);
		}

	})
	return body;
}

// This iterate over the list and return a array of all the html element
const generateList = (list) => {
	let localList = "<ul>";
	list.map(item =>
		localList = localList + ' <li>' + item + '</li>'
	)
	return localList + "</ul>"
}