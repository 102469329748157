import React, { useState, useEffect } from 'react';
import './Intro.page.scss';
import { BoutonHaxio } from '../../../components/ui/Boutons.component';
import { donnees } from '../../../services/content.service.jsx';
import { useHistory } from 'react-router-dom';
import Menu from '../../../components/layout/Menu/Menu.layout';
import ProgressSpinner from '../../../components/ui/ProgressSpinner.component';
import Modal from '../../../components/layout/Modal/Modal.layout';
import ErrorAlert from '../../../components/ui/ErrorAlert.component';
import { Grid } from '@material-ui/core';
import { theme } from '../../../theme/theme';
import ReactHtmlParser from 'react-html-parser';
import Footer from '../../../components/layout/Footer/Footer.layout';
import { getFocusOfSurvey } from '../../../services/questionnaire.service'
import authService from '../../../services/auth.service';



const Intro = (props) => {
	const page = 'intro';
	const [pageContent, setPageContent] = useState('');
	const [loading, setLoading] = useState(false);
	const [errorMessage, setMessage] = useState();
	const [userFocused, setUserFocused] = useState();
	const userData = authService.getCurrentUser();
	let history = useHistory();

	// Return the text to be displayed if you are focus or not
	const getCorpsText = () => {
		// If the id of the user focused is egal to the id of the logged in user
		if (userFocused?.id == userData.id) {
			return pageContent.corps?.textFocus
				.replace('$NombreCompetences', 'NOMBRE COMPETENCES')
				.replace('$NombreComportements ', 'NOMBRE COMPORTEMENTS')
		} else {
			return pageContent.corps?.text
				.replace('$PrenomFocus', userFocused?.firstName)
				.replace('$NomFocus', userFocused?.lastName)
		}
	}

	useEffect(() => {
		setLoading(true);
		//Getting focused user
		getFocusOfSurvey(props?.location?.state?.idQuestionnaire,props?.location?.state?.feuilleRoute).then(item => setUserFocused(item))
		// Getting data content
		donnees(page)
			.then((e) => {
				setPageContent(e.contents)
			})
			.catch((err) => setMessage(JSON.stringify(err.message)))
			.then(() => {
				setLoading(false);
			});
	}, [page]);

	const navigateToQuestionnaire = () => {
		history.push({
			pathname: '/questionnaire',
			state: {
				idQuestionnaire: props.location.state.idQuestionnaire,
				idQuestionHasUser: props.location.state.idQuestionHasUser,
				nextQuestion: props.location.state.nextQuestion,
				feuilleRoute: props.location.state.feuilleRoute,
			},
		})
	}

	return (
		<Grid container className="fondIntro">
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Menu />
					</Grid>
				</Grid>

				{loading ? (
					<Modal>
						<ProgressSpinner></ProgressSpinner>
					</Modal>
				) : (
						<Grid container direction="row" justify="center" alignItems="center" className="introContainer">
							<Grid item xs={11} sm={8} md={8} lg={8} xl={8}>
								<h1 style={theme.typography.h1}>{ReactHtmlParser(pageContent.titre?.text)}</h1>
								<p style={theme.typography}>{ReactHtmlParser(getCorpsText())}</p>
								<div className="boutonCentrer">
									<BoutonHaxio libelle={pageContent.button?.text} action={navigateToQuestionnaire} couleur="primary" />
								</div>
								<ErrorAlert errorMessage={errorMessage} />
							</Grid>
						</Grid>
					)}
			</Grid>
			<Footer />
		</Grid>
	);
};

export default Intro;
