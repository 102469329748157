import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#C77D00',
		},
		secondary: {
			main: '#374785',
		},
		error: {
			main: '#e91b1b',
			fontFamily: 'Century Gothic',
			textAlign: 'center',
			fontSize: '16px',
			height: '10px'
		},
		warning: {
			main: '#ff9800',
		},
		info: {
			main: '#2196f3',
		},
		text: {
			primary: '#000000',
			secondary: '#000000',
			disabled: '#000000',
		},
		action: {
			selected: '#FFFFFF',
			hover: '#C77D00',
			disabled: '#000000',
		},
	},
	typography: {
		fontFamily: 'Century Gothic',
		fontSize: 16,
		color: '#919191',

		h1: {
			fontFamily: 'Century Gothic',
			fontWeight: 'bold',
			fontSize: 30,
			lineHeight: 1.167,
			color: '#26305e',
			textAlign: 'center',
		},
		h2: {
			fontFamily: 'Century Gothic',
			fontWeight: 'normal',
			fontSize: 20,
			lineHeight: 1.167,
			letterSpacing: '-0.01562em',
			color: '##707070',
			textAlign: 'center',
		},
		h3: {
			fontFamily: 'Century Gothic',
			fontWeight: 'bold',
			fontSize: 17,
			lineHeight: 1.167,
			color: '#26305e',
			textAlign: 'center',
		},

		h4: {
			fontFamily: 'Century Gothic',
			fontWeight: 'normal',
			fontSize: 16,
			lineHeight: 1.167,
			color: '##707070',
			textAlign: 'center',
		},

		h5: {
			fontFamily: 'Century Gothic',
			fontWeight: 'bold',
			fontSize: 18,
			lineHeight: 1.167,
			letterSpacing: '-0.01562em',
			color: '#374785',
			textAlign: 'center',
		},
		p: {
			fontSize: '1rem',
			color: '#919191',

		}
	},
});

export const theme2 = createMuiTheme({
	typography: {
		fontFamily: 'Century Gothic',
		fontSize: 14,
		color: '#FFFFFF',

		h1: {
			fontFamily: 'Century Gothic',
			fontWeight: 'bold',
			fontSize: 30,
			lineHeight: 1.167,
			color: '#FFFFFF',
			textAlign: 'center',
		},
	},
});
