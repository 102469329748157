import React, { useState } from 'react';
import './Login.page.scss';
import { Checkbox, FormControlLabel, FormLabel, Grid } from '@material-ui/core';
import AuthService from '../../services/auth.service';
import { withRouter } from 'react-router-dom';
import { BoutonHaxio } from '../../components/ui/Boutons.component';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/ui/form/FormControl';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import Hidden from '@material-ui/core/Hidden';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import { theme } from '../../theme/theme';
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

function Login(props) {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setMessage] = useState('');
	const [hasAgree, setHasAgree] = useState(false)
	const [showDialog, setShowDialog] = useState(false)

	const initialValues = {
		email: '',
		password: '',
	};

	const validationSchema = Yup.object({
		email: Yup.string().email('Format email non valide').required('Email requis'),
		password: Yup.string().required('Mot de passe requis'),
	});

	const onSubmit = (values) => {
		setLoading(true);
		if (!hasAgree) {
			setLoading(false)
			return setMessage('Veuillez accepter les conditions générales d\'utilisation')
		}
		AuthService.login(values.email, values.password).then(
			() => {
				setLoading(false);
				props.history.push('/');
				window.location.reload();
			},
			(error) => {
				const resMessage =
					(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				setLoading(false);
				setMessage(resMessage);
			}
		);
	};

	return (
		<>
			<Grid container item>
				<Hidden smDown>
					<Grid container item md={6} lg={6} xl={6}>
						<Grid className="fondGaucheDesktop"></Grid>
					</Grid>
				</Hidden>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="fondDroite">
					<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
						{(formik) => {
							return (
								<Form className="form-signin">
									{loading && (
										<Modal>
											<ProgressSpinner></ProgressSpinner>
										</Modal>
									)}
									<img className="logo" alt="logo Haxio" width="50%" src="images/logo-haxio.png"></img>
									<h4 style={theme.typography.h4}>Connexion à votre espace</h4>
									<FormikControl control="input" type="email" label="Adresse Mail" name="email" />
									<FormikControl control="input" type="password" label="Mot de passe" name="password" />
									<div className={'form-control-row'} style={{ alignItems: 'flex-start' }}>
										<Checkbox
											style={{ padding: '0px' }} 
											color={'default'}
											checked={hasAgree} onChange={(event) => setHasAgree(event.target.checked)}
											name="hasAgree"
										/>
										<label className={'cgu-label'}>
										J’ai pris connaissance du traitement des données confié à Haxio afin de réaliser un questionnaire 360 feedback.
											<a onClick={() => setShowDialog(true)}> <br/>
												Pour en savoir plus sur les données et les droits.
											</a>
										</label>
									</div>
									<Grid container direction="row" justify="center" alignItems="center">
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="boutonCentrer padding">
											<BoutonHaxio libelle="Se connecter" couleur="primary" loading={loading && !formik.isValid} />
										</Grid>
									</Grid>
									<ErrorAlert errorMessage={errorMessage} />
									<p className="forgottenPassword" onClick={() => props.history.push('/forgotPassword')}>Mot de passe oublié</p>

								</Form>
							);
						}}
					</Formik>
					<span className={'versionNumber'}>{process.env.REACT_APP_VERSION}</span>
				</Grid>
			</Grid>
			<Dialog open={showDialog} className={'dialog-cgu'}>
				<DialogTitle className='dialog-title'>
					<div>
						Traitement des données confiées à Haxio
					</div>
					<div>
						<CloseIcon onClick={() => setShowDialog(false)}/>
					</div>
				</DialogTitle>
				<DialogContent>
					<DialogContentText className={'dialong-cgu-content'}>
						Les informations recueillies sur ce formulaire sont 
						enregistrées dans un fichier informatisé, géré par 
						les consultants haxio en charge du projet, dans le 
						but de réaliser un questionnaire à 360 feedback. 
						La base légale du traitement est l’intérêt légitime de l’employeur. 
						Les données collectées seront communiquées aux parties prenantes 
						définies en amont du projet. Les données sont conservées conformément 
						à la règlementation en vigueur de la CNIL. 
						Vous pouvez accéder aux données vous concernant, demander leur 
						rectification ou exercer votre droit à la limitation selon certaines 
						modalités. Vous pouvez retirer à tout moment votre consentement 
						au traitement de vos données. Vous pouvez également vous opposer au 
						traitement de vos données. Pour exercer ces droits ou pour toute 
						question sur le traitement de vos données dans ce dispositif, vous 
						pouvez contacter le prestataire à l’adresse dpo@haxio.fr . 
						Cependant, si vous estimez, après ces échanges, que vos droits 
						« Informatique et Libertés » ne sont pas respectés, vous pouvez 
						adresser une réclamation à la CNIL - Commission Nationale 
						Informatique et Libertés ‐ 3 Place de Fontenoy 
						TSA‐80715‐75334 PARIS CEDEX07
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default withRouter(Login);
