import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service';
const options = { headers: authHeader() };

/**
 * Manage a Get Request with header
 * [url]
 */
export async function doGet(url) {
	try {
		let req = await axios.get(url, options);
		return req;
	} catch (error) {
		if (AuthService.tokenError(error.response.status)) {
			window.location.reload();
		}
		return error;
	}
}
/**
 * Manage a Post Request with header
 * [url]
 * [data]
 */
export async function doPost(url, data) {
	try {
		let req = await axios.post(url, data, options);
		return req;
	} catch (error) {
		if (AuthService.tokenError(error.response.status)) {
			window.location.reload();
		}
		return error;
	}
}

/**
 * Manage a Put Request with header
 * [url]
 * [data]
 */
export async function doPut(url, data) {
	try {
		let req = await axios.put(url, data, options);
		return req;
	} catch (error) {
		if (AuthService.tokenError(error.response.status)) {
			window.location.reload();
		}
		return error;
	}
}
