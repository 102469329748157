import React from 'react';
import { Alert } from '@material-ui/lab';

function ErrorAlert(props) {
	const { errorMessage } = props;
	return (
		<div>
			{errorMessage && (
				<>
					<br />
					<Alert className="puff-in-center" severity="error">
						{errorMessage}
					</Alert>
				</>
			)}
		</div>
	);
}

export default ErrorAlert;
