import React from 'react';
import { Grid } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { useHistory } from 'react-router-dom';
import './Footer.layout.scss';

function Footer() {
	const history = useHistory()
	return (
		<Hidden smDown>
			<footer>
				<Grid container>
					<Grid item xs>
						©Haxio 2020
					</Grid>
					<Grid item xs onClick={() => history.push('/rgpd')} className={'footer-link'}>
						Protection des données personnelles
					</Grid>
					<Grid item xs onClick={() => history.push('/mentionsLegales')} className={'footer-link'}>
						Mentions légales
					</Grid>
					<Grid item xs onClick={() => history.push('/cgu')} className={'footer-link'}>
						Conditions générales d'utilisation
					</Grid>
				</Grid>
			</footer>
		</Hidden>
	);
}

export default Footer;
