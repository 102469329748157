import './Remerciement.page.scss';
import React, { useState, useEffect } from 'react';
import { donnees } from '../../../services/content.service';
import Menu from '../../../components/layout/Menu/Menu.layout';
import ProgressSpinner from '../../../components/ui/ProgressSpinner.component';
import Modal from '../../../components/layout/Modal/Modal.layout';
import ErrorAlert from '../../../components/ui/ErrorAlert.component';
import { theme } from '../../../theme/theme.jsx';
import { Grid } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Footer from '../../../components/layout/Footer/Footer.layout';
import ReactHtmlParser from 'react-html-parser';

const Remerciement = () => {
	const [remerciementcorps, setRemerciementcorps] = useState('');
	const page = 'remerciement';
	const [loading, setLoading] = useState(false);
	const [errorMessage, setMessage] = useState();

	useEffect(() => {
		donnees(page)
			.then((e) => {
				setRemerciementcorps(e.contents.corps.text);
			})
			.catch((err) => setMessage(JSON.stringify(err.message)))
			.then(() => {
				setLoading(false);
			});
	}, [page]);

	return (
		<Grid container className="fondRemerciement">
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Menu />
					</Grid>
					<Grid container>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Hidden xsDown>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<h1 style={theme.typography.h1}>Remerciement</h1>
								</Grid>
							</Hidden>
						</Grid>
					</Grid>
				</Grid>
				{loading ? (
					<Modal>
						<ProgressSpinner></ProgressSpinner>
					</Modal>
				) : (
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={11} sm={8} md={4} lg={4} xl={4}>
							<p className="centerText">
								<img id="Succes" alt="Success" src="images/Icon-check.png"></img>
							</p>
							<p style={theme.typography.h5}>{ReactHtmlParser(remerciementcorps)}</p>
							<ErrorAlert errorMessage={errorMessage} />
						</Grid>
					</Grid>
				)}
			</Grid>
			<Footer />
		</Grid>
	);
};

export default Remerciement;
