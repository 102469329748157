import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import AuthService from '../../services/auth.service';
import { BoutonHaxio } from '../../components/ui/Boutons.component';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/ui/form/FormControl';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import Hidden from '@material-ui/core/Hidden';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import { Redirect } from 'react-router-dom';
import { theme } from '../../theme/theme';

function SignUp(props) {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setMessage] = useState('');
	const [redirecting, setRedirecting] = useState(false);

	const initialValues = {
		password: '',
		passwordConfirmation: '',
	};

	useEffect(() => {
		AuthService.isTokenable(props).then((token) => {
			setRedirecting(!token);
		});
	}, [props]);

	if (redirecting) {
		return <Redirect to="/login" />;
	}

	const validationSchema = Yup.object({
		password: Yup.string().required('Mot de passe requis'),
		passwordConfirmation: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Le mot de passe doit être identique')
			.required('Confimer le mot de passe'),
	});
	const onSubmit = (values) => {
		setLoading(true);
		AuthService.signUp(JSON.parse(localStorage.getItem('newUser')).email, values.password).then(
			() => {
				setLoading(false);
				props.history.push('/');
				window.location.reload();
			},
			(error) => {
				const resMessage =
					(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				setLoading(false);
				setMessage(resMessage);
			}
		);
	};

	return (
		<Grid container>
			<Hidden smDown>
				<Grid container item md={6} lg={6} xl={6}>
					<Grid item className="fondGaucheDesktop"></Grid>
				</Grid>
			</Hidden>
			<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="fondDroite">
				<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
					{(formik) => {
						return (
							<Form className="form-signin">
								{loading && (
									<Modal>
										<ProgressSpinner></ProgressSpinner>
									</Modal>
								)}
								<img className="logo" alt="logo Haxio" width="50%" src="images/logo-haxio.png"></img>
								<h4 style={theme.typography.h4}>Première connexion à votre espace</h4>
								<p style={theme.typography} className={'centerText'}>
									Définissez un mot de passe
								</p>

								<FormikControl control="input" type="password" label="Mot de passe" name="password" />
								<FormikControl control="input" type="password" label="Confirmer Mot de passe" name="passwordConfirmation" />
								<Grid container direction="row" justify="center" alignItems="center">
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="boutonCentrer padding">
										<BoutonHaxio libelle="Se connecter" couleur="primary" loading={loading && !formik.isValid} />
									</Grid>
								</Grid>
								<ErrorAlert errorMessage={errorMessage} doOpen={!!errorMessage} />
							</Form>
						);
					}}
				</Formik>
			</Grid>
		</Grid>
	);
}

export default SignUp;
