import React, { useEffect } from 'react';
import './Rgpd.page.scss';
import Menu from '../../components/layout/Menu/Menu.layout';
import { theme } from '../../theme/theme';
import { Grid } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import Footer from '../../components/layout/Footer/Footer.layout';
import content from './content.json'
import { generateBody } from '../../services/content.service'

const Rgpd = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	})
	return (
		<Grid container className="fondRgpd">
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Menu />
					</Grid>
				</Grid>
				<Grid container direction="row" justify="center" alignItems="center" >
					<Grid item xs={11} sm={8} md={8} lg={8} xl={8}>

						<div className="rgpd-body-wrapper">
							<h1 style={theme.typography.h1}>{content.title}</h1>
							{ReactHtmlParser(generateBody(content))}
						</div >

					</Grid>
				</Grid>
			</Grid>
			<Footer />
		</Grid>
	);
};

export default Rgpd;
