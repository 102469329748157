import { doPut, doGet } from '../services/WrapperProtectedAxiosReq.service';
const API_URL = process.env.REACT_APP_API_URL + 'surveyHasUser';


	/**
	 * Update progression for a current survey
	 * [surveyHasUserId]
	 * [progression]
	 */
	export async function updateProgression(...props) {
		const [surveyHasUserId, progression, nextQuestion] = props;
		try {
			let req = await doPut(`${API_URL}/update/`, {
				surveyHasUserId: surveyHasUserId,
				progression: progression,
				nextQuestion: nextQuestion,
			});
			return req;
		} catch (error) {
			return error;
		}
	}



	//Modification du status du survey has user
	export async function updateSurveyHasUser(idFeuilleDeRoute, status) {
		let req = await doPut(API_URL + '/update/' + idFeuilleDeRoute, {
			status: status,
		});
		let data = req.data;

		return data;
}


