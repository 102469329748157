import React from 'react';
import './Heading.layout.scss';

import { theme } from '../../../theme/theme';
function Heading({ header, paragraphe }) {
	return (
		<div className={'heading'}>
			<h1 style={theme.typography.h1}>{header}</h1>
			<p style={theme.typography}>{paragraphe}</p>
		</div>
	);
}

export default Heading;
