import './App.css';
import Login from './pages/login/Login.page';
import React from 'react';
import { BrowserRouter, Route, useHistory } from 'react-router-dom';
import Intro from './pages/questionnaire/Intro/Intro.page';
import Edito from './pages/edito/Edito.page';
import Cgu from './pages/cgu/Cgu.page';
import Rgpd from './pages/rgpd/Rgpd.page';
import MentionsLegales from './pages/mentionslegales/MentionsLegales.page';
import { Switch } from 'react-router-dom';
import Questionnaire from './pages/questionnaire/Questionnaire/Questionnaire.page';
import Remerciement from './pages/questionnaire/Remerciement/Remerciement.page';
import ListeQuestionnaire from './pages/liste/ListeQuestionnaire.page';
import ProtectedRoute from './components/utils/ProtectedRoute.component';
import AuthService from '../src/services/auth.service';
import LogOut from './components/utils/logOut.component';
import TableauDeBord from './pages/tableauDeBord/tableauDeBord.page';
import SignUp from '../src/pages/signUp/signUp.page';
import ForgotPassword from './pages/login/ForgotPassword.page';
import ResetPassword from './pages/login/ResetPassword.page';

function App() {
	let history = useHistory()
	return (
		<div className="App">
			<BrowserRouter>
				<Switch>
					<ProtectedRoute path="/intro" component={Intro} />
					<Route path="/signUp/:token" component={SignUp} />
					<Route path="/login" component={() => (AuthService.isAuthenticated() ? history.push('/') : <Login />)} />
					<Route path="/forgotPassword" component={ForgotPassword} />
					<Route path="/resetPassword" component={ResetPassword} />

					<ProtectedRoute path="/questionnaire" component={Questionnaire} />
					<ProtectedRoute path="/remerciement" component={Remerciement} />
					<ProtectedRoute path="/listeQuestionnaire" component={ListeQuestionnaire} />
					<ProtectedRoute path="/cgu" component={Cgu} />
					<ProtectedRoute path="/rgpd" component={Rgpd} />
					<ProtectedRoute path="/mentionsLegales" component={MentionsLegales} />
					<ProtectedRoute path="/logOut" component={LogOut} />
					<ProtectedRoute path="/edito" component={Edito} />
					<Route path="/:token" component={SignUp} />

					<ProtectedRoute path="/" component={TableauDeBord} />
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
