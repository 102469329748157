import React, { useEffect } from 'react';
import './Cgu.page.scss';
import { donnees } from '../../services/content.service.jsx';
import Menu from '../../components/layout/Menu/Menu.layout';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import { theme } from '../../theme/theme';
import { Grid } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import Footer from '../../components/layout/Footer/Footer.layout';
import { generateBody } from '../../services/content.service'
import content from './content.json'

const Cgu = () => {

	useEffect(() => {
		window.scrollTo(0, 0)
	})

	return (
		<Grid container className="fondCgu">
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Menu />
					</Grid>
				</Grid>
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={11} sm={8} md={8} lg={8} xl={8}>
						<div className="cgu-body-wrapper">
							<h1 style={theme.typography.h1}>{content.title}</h1>
							{ReactHtmlParser(generateBody(content))}
						</div >
					</Grid>
				</Grid>
			</Grid>
			<Footer />

		</Grid>
	);
};

export default Cgu;
