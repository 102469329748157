import React from 'react';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../theme/theme.jsx';


export function BoutonBasic(props) {
	const { action, libelle, couleur, loading, small, arrondi, icon } = props;
	const styleBouton = {
		borderRadius: `${arrondi ? '20px' : '0px'}`,
		padding: '5px 35px 5px 35px',
		textTransform: 'none',
		containedPrimary: `${small ? 'validationButtonSmall' : 'validationButton'}`,
		backgroundColor: '#C77D00'
	};

	return (
		<>
			<Button
				style={styleBouton}
				variant="contained"
				type="submit"
				color={couleur}
				onClick={action}
				disabled={loading}
				startIcon={icon}
				onClick={() => props.onClick ? props.onClick() : null}
			>
				{libelle}
			</Button>
		</>
	);
}

export function BoutonHaxio(props) {
	const { action, libelle, couleur, loading, small } = props;
	return (
		<>
			<ThemeProvider theme={theme}>
				<Button
					classes={{ containedPrimary: `${small ? 'validationButtonSmall' : 'validationButton'}` }}
					variant="contained"
					type="submit"
					color={couleur}
					onClick={action}
					disabled={loading}
				>
					{libelle}
				</Button>
			</ThemeProvider>
		</>
	);
}

export function BoutonTableauDeBord(props) {
	const { action, libelle, couleur, nombre } = props;
	const buttonStyled = {
		width: '20rem',
		height: '10rem',
		backgroundColor: `${theme.palette[couleur].main}`,
		color: 'white',
		border: 'none',
		fontFamily: 'Century Gothic',
		display: 'inline-block',
		margin: '15px 0',
		cursor: nombre === 0 ? 'default' : 'pointer',
	};
	const numberStyled = {
		textAlign: 'center',
		fontSize: '4em',
		margin: '15px 0 0 0',
	};
	const labelStyled = {
		textAlign: 'center',
		fontSize: '1.2em',
		margin: '0',
	};
	return (
		<center>
			<div style={buttonStyled} onClick={action}>
				<p style={numberStyled}>{nombre}</p>
				<p style={labelStyled}>{libelle}</p>
			</div>
		</center>
	);
}
