import { doPost, doGet } from '../services/WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL + 'answer/';
const user = JSON.parse(localStorage.getItem('user'));
class AnswerService {
	/**
	 * Set la reponse d'un utilisateur
	 * [surveyQuestionId]
	 * [chosenOption]
	 * [feuilleRoute]
	 */
	async setUserAnswer(...props) {
		const [surveyQuestionId, chosenOption, feuilleRoute] = props;
		try {
			let req = await doPost(`${API_URL}saveAnswerFromUserSurvey`, {
				userId: user.id,
				surveyQuestionId: surveyQuestionId,
				chosenOption: chosenOption,
				feuilleRoute: feuilleRoute,
			});
			return req;
		} catch (error) {
			return error;
		}
	}

	/**
		 * Get answer if it exist on specific question
		 * [surveyQuestionId]
		 * [feuilleRoute]
		 */
	async getUserAnswerOnQuestion(...props) {
		const [surveyQuestionId, feuilleRoute] = props;
		try {
			let req = await doGet(`${API_URL}getAnswerFromUserSurvey/?surveyQuestionId=` + surveyQuestionId + '&userId=' + user.id + '&feuilleRoute=' + feuilleRoute);
			return req;
		} catch (error) {
			return error;
		}
	}


}
export default new AnswerService();
