import React from 'react';
import PropTypes from 'prop-types';
import { BoutonHaxio } from '../../ui/Boutons.component'
import './styles.scss'
import { theme } from '../../../theme/theme';

const Popup = (props) => {
    return (
        <div className={'popup-wrapper'}>
            <div className={'content-wrapper'}>
                <h1 className={'heading'}>{props.title}</h1>
                <span style={theme.typography.h4}>{props.text}</span>
                <BoutonHaxio libelle={'Fermer la notification'} action={() => props.onClick()} couleur="secondary"/>
            </div>
        </div>
    )

}
export default Popup

// React strict mode consider PropTypes as a typo...
// Popup.PropTypes = {
//     title: PropTypes.string.isRequired,
//     text: PropTypes.text.isRequired,
//     onClick: PropTypes.func.isRequired
// }