import React, { useState, useEffect } from 'react';
import './tableauDeBord.page.scss';
import { BoutonTableauDeBord } from '../../components/ui/Boutons.component';
import { useHistory } from 'react-router-dom';
import Menu from '../../components/layout/Menu/Menu.layout';
import { donnees } from '../../services/content.service.jsx';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import { chargerCompteurQuestionnaire } from '../../services/questionnaire.service';
import { Grid } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { theme } from '../../theme/theme';
import Footer from '../../components/layout/Footer/Footer.layout';
const TableauDeBord = () => {
	const page = 'tableauDeBord';
	const [tableauBordCorps, setTableauBordCorps] = useState('');
	const [tableauBordTitre, setTableauBordTitre] = useState('');
	const [loading, setLoading] = useState(false);
	const [errorMessage, setMessage] = useState();
	const [EnCours, setEnCours] = useState('');
	const [EnAttente, setEnAttente] = useState('');
	let history = useHistory()

	useEffect(() => {
		setLoading(true);
		// EnCours questions
		chargerCompteurQuestionnaire(1, 99)
			.then((e) => {
				setEnCours(e);
			})
			.catch((err) => setMessage(JSON.stringify(err.message)))

		// EnAttente questions
		chargerCompteurQuestionnaire(0, 0)
			.then((e) => {
				setEnAttente(e);
			})
			.catch((err) => setMessage(JSON.stringify(err.message)))

		// Title and corps of the page
		donnees(page)
			.then((e) => {
				setTableauBordTitre(e.contents.titre.text);
				setTableauBordCorps(e.contents.corps.text);
			})
			.catch((err) => setMessage(JSON.stringify(err.message)))
			.then(() => {
				setLoading(false);
			});
	}, []);


	const navigateToListeQuestionnaire = (action) => {
		history.push({
			pathname: '/listeQuestionnaire',
			state: { typePage: action },
		})
	}

	return (
		<Grid container className="fondTableauBord">
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Menu />
					</Grid>
				</Grid>

				{loading ? (
					<Modal>
						<ProgressSpinner></ProgressSpinner>
					</Modal>
				) : (
						<Grid container direction="row" justify="center" alignItems="center" className="tableauBordContainer">
							<Grid item xs={11} sm={8} md={8} lg={8} xl={8}>
								<h1 style={theme.typography.h1}>{ReactHtmlParser(tableauBordTitre)}</h1>
								<p style={theme.typography.p}>{ReactHtmlParser(tableauBordCorps)}</p>
								{/* TODO mettre du camel case !*/}

								<Grid container>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<BoutonTableauDeBord
											libelle="questionnaire(s) en cours"
											action={() => (EnCours > 0 ? navigateToListeQuestionnaire('listeEncours') : '')}
											couleur="primary"
											nombre={EnCours}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<BoutonTableauDeBord
											libelle="questionnaire(s) en attente"
											action={() => (EnAttente > 0 ? navigateToListeQuestionnaire('listeEnattente') : '')}
											couleur="secondary"
											nombre={EnAttente}
										/>
									</Grid>
								</Grid>
								<ErrorAlert errorMessage={errorMessage} />
							</Grid>
						</Grid>
					)}
			</Grid>
			<Footer />
		</Grid>
	);
};

export default TableauDeBord;
