import { doGet, doPost } from './WrapperProtectedAxiosReq.service';

export function chargerLibelleBouton() {
	const LibelleBouton = 'Valider';
	return LibelleBouton;
}
export function recupTitreEncours() {
	const titreEncours = 'Questionnaire en cours';
	return titreEncours;
}
export function recupTitreEnAttente() {
	const titreEnAttente = 'Questionnaire en attente';
	return titreEnAttente;
}

const API_URL = process.env.REACT_APP_API_URL;
const user = JSON.parse(localStorage.getItem('user'));

export async function getQuestionsForOneSurvey(idQuestionnaire) {
	try {
		let req = await doGet(API_URL + 'surveyQuestion/?idSurvey=' + idQuestionnaire);
		let questions = req.data.map((q) => {
			let _question = {
				id_survey_question: q.id,
				...q.question,
			};
			return _question;
		});
		return questions;
	} catch (error) {
		return error;
	}
}

function getQuestionnaireUser(progressionStart, progressionEnd) {
	if (user && user.id) {
		return doGet(
			API_URL +
			'surveyHasUser/' +
			'?userId=' +
			user.id +
			'&progressionStart=' +
			progressionStart +
			'&progressionEnd=' +
			progressionEnd
		);
	}
	return null;
}

export async function getFocusOfSurvey(surveyId, feuilleRoute) {
	try {
		let req = await doPost(`${API_URL}questionnaire/focusId`, {
			surveyId: surveyId,
			feuilleRoute:feuilleRoute
		}).then(item => item.data);
		return req;
	} catch (error) {
		return error;
	}
}

export async function getNumbersOnSurvey(surveyId) {
	try {
		let req = await doPost(`${API_URL}questionnaire/surveyNumbers`, {
			surveyId: surveyId,
		}).then(item => item.data);
		return req;
	} catch (error) {
		return error;
	}
}

export async function chargerCompteurQuestionnaire(progressionStart, progressionEnd) {
	let req = await getQuestionnaireUser(progressionStart, progressionEnd);
	let data = req.data.count;
	return data;
}

export async function chargeListeQuestionnaire(progressionStart, progressionEnd) {
	let req = await getQuestionnaireUser(progressionStart, progressionEnd);
	let data = req.data.rows;
	return data;
}
