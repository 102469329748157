import React, { useEffect, useState } from 'react';
import './ListeQuestionnaire.page.scss';
import Menu from '../../components/layout/Menu/Menu.layout';
import { ListeHaxio } from '../../components/ui/Liste.component';
import { useHistory } from 'react-router-dom';
import { donnees } from '../../services/content.service.jsx';
import { chargeListeQuestionnaire } from '../../services/questionnaire.service';
import { Grid } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import Heading from '../../components/layout/Heading/Heading.layout';
import Footer from '../../components/layout/Footer/Footer.layout';

const ListeQuestionnaire = (props) => {
	const [Questionnaires, setQuestionnaire] = useState('');
	const [ListeTitre, setListeTitre] = useState('');
	const [ListeCorps, setListeCorps] = useState('');
	let history = useHistory();


	useEffect(() => {
		let start = props.location.state.typePage === 'listeEncours' ? 1 : 0;
		let end = props.location.state.typePage === 'listeEncours' ? 99 : 0;
		async function syncQuestionnaire() {
			const TabQuestionnaires = await chargeListeQuestionnaire(start, end);
			console.log("TabQuestionnaires", TabQuestionnaires)
			setQuestionnaire(TabQuestionnaires);
		}
		syncQuestionnaire();
		donnees(props.location.state.typePage).then((e) => {
			if (e && e.contents) {
				setListeTitre(e.contents.titre.text);
				setListeCorps(e.contents.corps.text);
			}
		});
	}, [props.location.state.typePage]);

	const navigateToEdito = (idQuestionnaire, idQuestionHasUser, nextQuestion, feuilleRoute) => {
		history.push({
			pathname: '/edito',
			state: {
				idQuestionnaire: idQuestionnaire,
				idQuestionHasUser: idQuestionHasUser,
				nextQuestion: nextQuestion,
				feuilleRoute: feuilleRoute,
			},
		})
	}

	return (
		<Grid container>
			<Grid item xs={12} md={12}>
				<Grid container>
					<Grid item xs={12} md={12}>
						<Menu visible={true} />
					</Grid>
				</Grid>
				<Grid container className="containerListe">
					<Grid item xs={12} md={12}>
						<Grid container direction="row" justify="center" alignItems="center">
							<Grid item xs={11} md={8}>
								<Heading header={ReactHtmlParser(ListeTitre)} paragraphe={ReactHtmlParser(ListeCorps)} />
							</Grid>
						</Grid>
						<Grid container direction="row" justify="center" alignItems="center">
							<Grid item xs={12} md={4}>
								{Questionnaires && (
									<ListeHaxio
										tableau={Questionnaires}
										primary="survey"
										id="surveyId"
										secondary="progression"
										secondary2="createdAt"
										secondary3="client"
										type={props.location.state.typePage}
										action={(idQuestionnaire, idQuestionHasUser, nextQuestion, feuilleRoute) => {
											navigateToEdito(idQuestionnaire, idQuestionHasUser, nextQuestion, feuilleRoute);
										}}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Footer />
		</Grid>
	);
};

export default ListeQuestionnaire;
