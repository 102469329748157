import React, { useState } from 'react';
import './Menu.layout.scss';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

function TemporaryDrawer(props) {
	const styleBouton = {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
	};
	const [right, setRight] = useState(false);
	const iconColor = props.iconColor ? props.iconColor : '#919191';
	const history = useHistory()

	const toggleDrawer = (open) => () => {
		setRight(open);
	};

	const menuItems = [

		{
			label: 'Tableau de bord',
			path: '/',
		}, {
			label: 'Questionnaire(s) en cours',
			path: '/listeQuestionnaire',
			typePage: 'listeEncours'
		},
		{
			label: 'Questionnaire(s) en attente',
			path: '/listeQuestionnaire',
			typePage: 'listeEnAttente'
		},
		{
			label: 'Déconnexion',
			path: '/logOut',
		},

	];

	const list = () => (
		<div onClick={toggleDrawer(false)}>
			<List>
				{menuItems.map((item, index) => (
					<ListItem button key={item.label}>
						<Link to={{ pathname: item.path, state: { typePage: item.typePage } }} style={{ textDecoration: 'none', color: '#26305e' }}>
							{item.label}
						</Link>
					</ListItem>
				))}
			</List>
		</div >
	);


	return (
		<Grid container direction="row" justify="flex-end" alignItems="center">
			{props.visible && (
				<Grid item xs={2} md={2}>
					<Button onClick={() => history.push('/')}>
						<ArrowBackIcon style={{ color: `#AAA7A7`, fontSize: '2em' }} />
					</Button>
				</Grid>
			)}

			<Grid item xs={props.visible ? 8 : 10} md={props.visible ? 8 : 10}>
				<Grid container>
					<Grid item xs={12} md={12}>
						<Hidden xsUp>
							<center>
								<img className="logoHaxio-mobile" alt="logo Haxio"
									src={props.iconColor == 'white' ? "images/logo-haxio-blanc.png" : "images/logo-haxio.png"} onClick={() => history.push('/')} />
							</center>
						</Hidden>
						<img className="logoHaxio" alt="logo Haxio"
							src={props.iconColor == 'white' ? "images/logo-haxio-blanc.png" : "images/logo-haxio.png"} onClick={() => history.push('/')} />
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={2} md={2}>
				<Grid container direction="row" justify="flex-end" alignItems="center">
					<Grid item xs={12} md={12}>
						<Button style={styleBouton} onClick={toggleDrawer(true)}>
							<MenuIcon style={{ color: `${iconColor}` }} />
						</Button>
						<span
							style={{
								color: `${iconColor}`,
								font: 'Regular 10px/56px Century Gothic',
								letterSpacing: '0px',
								fontSize: '0.7em',
								textAlign: 'center',
							}}
						>
							<Grid container>
								<Grid item xs={12} md={12}>
									Menu
								</Grid>
							</Grid>
						</span>
						<Drawer anchor={'right'} open={right} onClose={toggleDrawer(false)}>
							{list()}
						</Drawer>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
export default TemporaryDrawer;
