import React from 'react';

import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButton from './RadioButton';

const FormikRadioGroup = ({ field, form: { touched, errors }, name, options, onChange, ...props }) => {
	const fieldName = name || field.name;
	const radioContainer = {
		marginLeft: 'auto',
		marginRight: 'auto',
		width: 'fit-content',
	};

	const shouldBeChecked = (option) => {
		return (props.previousUserValue || field.value) === option.id
	}
	return (
		<div style={radioContainer}>
			<RadioGroup {...field} {...props} name={fieldName} onChange={event => onChange(event.target.value)}>
				{options.map((option) => (
					<RadioButton
						value={option.id}
						checked={shouldBeChecked(option)}
						onChange={field.onChange(fieldName)}
						label={option.id}
						id={fieldName}
						key={option.id}
					/>
				))}
			</RadioGroup>

			{touched[fieldName] && errors[fieldName] && <React.Fragment>{errors[fieldName]}</React.Fragment>}
		</div>
	);
};

export default FormikRadioGroup;
