import React, { useEffect, useState } from 'react';
import './Questionnaire.page.scss';
import { useHistory } from 'react-router-dom';
import { BoutonHaxio } from '../../../components/ui/Boutons.component';
import Menu from '../../../components/layout/Menu/Menu.layout';
import { getQuestionsForOneSurvey, chargerLibelleBouton } from '../../../services/questionnaire.service.jsx';
import ProgressBar from '../../../components/ui/ProgressBar.component';
import ProgressSpinner from '../../../components/ui/ProgressSpinner.component';
import Modal from '../../../components/layout/Modal/Modal.layout';
import ErrorAlert from '../../../components/ui/ErrorAlert.component';
import { Formik, Form, Field } from 'formik';
import FormikRadioGroup from '../../../components/ui/form/RadioGroup';
import { Grid } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import AnswerService from '../../../services/answer.service';
import { updateProgression, updateSurveyHasUser } from '../../../services/survey_has_user.service';
import { theme } from '../../../theme/theme';
import Footer from '../../../components/layout/Footer/Footer.layout';
import Popup from '../../../components/utils/Popup'
import authService from '../../../services/auth.service';
import { getFocusOfSurvey } from '../../../services/questionnaire.service'

const Questionnaire = (props) => {
	const [questions, setQuestions] = useState('');
	const [indexQuestionActive, setIndexQuestionActive] = useState(props.location.state.nextQuestion || 0);
	const [estTermine, setEstTermine] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setMessage] = useState();
	const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState();
	const [dontKnowAnswer, setDontKnowAnswer] = useState(0);
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [feuilleRoute] = useState(props.location.state.feuilleRoute);
	const [userFocused, setUserFocused] = useState();
	const [isButtonActive, setButtonActive] = useState(false);
	const [openQuestionResponse, setOpenQuestionResponse] = useState("");
	const history = useHistory()

	const userData = authService.getCurrentUser();


	/**
	 * Get all question and set all answer in one array
	 */
	useEffect(() => {
		//Getting focused user
		async function syncQuestion() {
			let localUserFocused = await getFocusOfSurvey(props?.location?.state?.idQuestionnaire,props?.location?.state?.feuilleRoute).then(item => item)
			setUserFocused(localUserFocused)
			try {
				setLoading(true);
				const _questions = await getQuestionsForOneSurvey(props.location.state.idQuestionnaire);
				if (userData.id == localUserFocused.id) {
					//Removing open question if user is focused (last 3 questions)
					_questions.splice(_questions.length - 3, 3)
				}
				// Getting answer if already exist
				_questions.map((question) => {
					if (question.answerTypeId != 2) {
						return (question.answer = JSON.parse(question.answer));
					} else {
						return ""
					}
				});

				setQuestions(_questions);

			} catch (error) {
				setMessage(JSON.stringify(error.message));
			}
			setLoading(false);
		}
		syncQuestion();
	}, [props.location.state.idQuestionnaire]);

	useEffect(() => {
		if (questions) {
			getUserAnswer()
		}
	}, [questions, indexQuestionActive])

	/**
	 * Retrieve the current active question
	 */
	function getCurrentQuestion() {
		return questions && questions[indexQuestionActive];
	}

	function getUserAnswer() {
		setOpenQuestionResponse('')
		// Get answer from user for current question if exist
		return AnswerService.getUserAnswerOnQuestion(getCurrentQuestion().id_survey_question, feuilleRoute).then(
			item => {
				setOpenQuestionResponse(item.data.chosenOption)
				setCurrentQuestionAnswer(item.data)
			}
		)
	}

	/**
	 * Handle the submit, post the answer  & check if surevy is finished
	 */
	function handleSubmitReponse(reponse) {
		console.log(reponse)
		let chosenOption;
		// If question is open set answer from state, else set it from formik
		if (reponse == 2) {
			chosenOption = openQuestionResponse
		} else {
			chosenOption = reponse.radioGroup;
		}
		const surveyQuestionId = getCurrentQuestion().id_survey_question;
		const surveyHasUserId = props.location.state.idQuestionHasUser;
		let progression = 0;
		let nextQuestion = null;

		// Checking that the user does not answer "Ne sais pas" more than 3 times
		if (reponse?.radioGroup == "Ne sais pas") {
			setDontKnowAnswer(dontKnowAnswer + 1)
			if (dontKnowAnswer + 1 == 3) {
				setIsPopupVisible(true)
			}
		}

		AnswerService.setUserAnswer(surveyQuestionId, chosenOption, feuilleRoute)
			.then((resp) => {
				if (questions[indexQuestionActive + 1] === undefined) {
					setEstTermine(true);	
					progression = 100;
				} else {
					nextQuestion = indexQuestionActive + 1;
					progression = (nextQuestion * 100) / questions.length;
					setIndexQuestionActive(nextQuestion);
				}
			})
			.then(() => {
				updateProgression(surveyHasUserId, progression, nextQuestion);
				//Reset open answer
				setOpenQuestionResponse("")
			})
			.catch((error) => setMessage(JSON.stringify(error.message)));

	}


	if (estTermine) {
		//This timeout is only here for aesthetic reason, allowing the progress bar to be fully finished before navigation
		setTimeout(() => { history.push("/remerciement") }
			, 500)
	}

	/**
	 * Is trigger on submission if the form is valid
	 */
	const onSubmit = (values, { resetForm }) => {
		setButtonActive(false)
		handleSubmitReponse(values);
		resetForm({});
	};

	return (
		<Grid container className="fondQuestionnaire">
			{isPopupVisible ? <Popup onClick={() => setIsPopupVisible(false)} title={'Trop de « Ne sais pas »'} text={'Merci de limiter l’utilisation de la réponse « ne sais pas » à 3 fois par questionnaire s\'il vous plait.'} /> : null}
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Menu />
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Hidden xsDown>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<h1 style={theme.typography.h1}> {userFocused?.id === userData.id ? "Mon Questionnaire 360" : "Questionnaire 360 de " + userFocused?.firstName + " " + userFocused?.lastName}</h1>
							</Grid>
						</Hidden>
					</Grid>
				</Grid>
				<Grid container direction="row" justify="center" alignItems="center">
					<div className={'questions-navigation-wrapper'}>
						<a className={'left-arrow'} onClick={() => { setIndexQuestionActive(indexQuestionActive - 1);setButtonActive(false) }}>  {indexQuestionActive != 0 ? "<" : ""} </a>
						{indexQuestionActive + 1} / {questions.length}
						<a className={currentQuestionAnswer ? 'right-arrow' : 'right-arrow-disable'}
							onClick={currentQuestionAnswer ? () => { setIndexQuestionActive(indexQuestionActive + 1);setButtonActive(false) } : null}> {">"} </a>
					</div>
				</Grid>
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={11} sm={8} md={8} lg={8} xl={8} className="ProgressBar">
						<ProgressBar nbrEtape={questions.length} etapeCourrante={estTermine ? questions.length : indexQuestionActive} />
					</Grid>
				</Grid>
				{loading ? (
					<Modal>
						<ProgressSpinner></ProgressSpinner>
					</Modal>
				) : (
						<Grid container direction="row" justify="center" alignItems="center" className="fondBlanc">
							<Grid item xs={11} sm={8} md={8} lg={8} xl={8}>
								{/* MCQ */}
								{getCurrentQuestion()?.answer && (
									<Formik initialValues={{ radioGroup: '' }} onSubmit={onSubmit}>
										{(formik) => {
											return (
												<Form className="fondSurvey">
													<div>
														<h3 style={theme.typography.h3}>{getCurrentQuestion()?.label}</h3>
														<Field
															name="radioGroup"
															component={FormikRadioGroup}
															options={getCurrentQuestion()?.answer}
															currentQuestionAnswer={currentQuestionAnswer}
															previousUserValue={currentQuestionAnswer?.chosenOption}
															onChange={(item) => {
																setCurrentQuestionAnswer({ chosenOption: item })
																setButtonActive(true)
															}}
														/>
													</div>

													<div className="boutonCentrer">
														<BoutonHaxio
															libelle={"Valider"}
															couleur="primary"
															loading={!isButtonActive}
														/>
													</div>
													<ErrorAlert errorMessage={errorMessage} />
												</Form>
											);
										}}
									</Formik>
								)}
								{/* Open question */}
								{getCurrentQuestion()?.answerTypeId == 2 &&
									<div className={'openQuestion-wrapper'}>
										<h3>{getCurrentQuestion().label.replace("$LastName", userFocused?.lastName).replace("$FirstName", userFocused?.firstName)}</h3>
										<textarea onChange={(e) => setOpenQuestionResponse(e.target.value)} value={openQuestionResponse} />
										<div className="boutonCentrer">
											<BoutonHaxio
												libelle={"Valider"}
												couleur="primary"
												loading={openQuestionResponse ? false : true}
												// Sending 2 as parameters to tell the function we are in open answer mode
												action={() => handleSubmitReponse(2)}
											/>
										</div>
									</div>}
							</Grid>
						</Grid>
					)}
			</Grid>
			<Footer />
		</Grid >
	);
};

export default Questionnaire;
