import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import { withStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';

const BorderLinearProgress = withStyles(() => ({
	root: {
		height: 24,
		width: '80%',
		margin: 'auto',
	},
	colorPrimary: {
		backgroundColor: 'white',
	},
	bar: {
		backgroundColor: theme.palette.primary.main,
	},
}))(LinearProgress);

function ProgressBar({ nbrEtape, etapeCourrante }) {
	const [progress, setProgress] = React.useState(0);

	React.useEffect(() => {
		let currentProgression = (etapeCourrante * 100) / nbrEtape;
		setProgress(currentProgression);
	}, [etapeCourrante, nbrEtape]);
	return (
		<div>
			<BorderLinearProgress variant="determinate" value={progress} />
		</div>
	);
}

export default ProgressBar;
