import React, { useState, useEffect } from 'react';
import authService from '../../services/auth.service';
import './Edito.page.scss';
import { donnees } from '../../services/content.service.jsx';
import { useHistory } from 'react-router-dom';
import { BoutonHaxio } from '../../components/ui/Boutons.component';
import Menu from '../../components/layout/Menu/Menu.layout';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import { Grid } from '@material-ui/core';
import { theme2 } from '../../theme/theme.jsx';
import ReactHtmlParser from 'react-html-parser';
import Footer from '../../components/layout/Footer/Footer.layout';
import { getFocusOfSurvey, getNumbersOnSurvey } from '../../services/questionnaire.service'

const Edito = (props) => {
	const page = 'edito';
	const [pageContent, setPageContent] = useState('');
	const [loading, setLoading] = useState(false);
	const [errorMessage, setMessage] = useState();
	const [userFocused, setUserFocused] = useState();
	const [surveyInfos, setSurveyInfos] = useState();
	const userData = authService.getCurrentUser();
	let history = useHistory()


	// Return the text to be displayed if you are focus or not
	const getCorpsText = () => {
		// If the id of the user focused is egal to the id of the current user
		// Iffocused, we need to change the name of the user and add info on the survey length
		if (userFocused?.id == userData.id) {
			console.log(surveyInfos)
			return pageContent.corps?.textFocus.replace('$Prenom', userData?.firstname)
				.replace('$Nom', userData?.lastname)
				.replace('$NombreCompetences', (" " + (surveyInfos?.skillsNumber - 1) + " ") || " 0 ")
				.replace('$NombreComportements ', (" " + (surveyInfos?.conductsNumber - 3) + " ") || " 0 ")
		} else {
			// If the user is not focused, we need to set his name and the name of the focused user
			return pageContent.corps?.text.replace('$Prenom', userData?.firstname)
				.replace('$Nom', userData?.lastname)
				.replace('$PrenomFocus', userFocused?.firstName)
				.replace('$NomFocus', userFocused?.lastName)
		}
	}

	useEffect(() => {
		setLoading(true);
		//Getting focused user
		getFocusOfSurvey(props?.location?.state?.idQuestionnaire,props?.location?.state?.feuilleRoute).then(item => setUserFocused(item))
		//Getting numbers on the survey
		getNumbersOnSurvey(props?.location?.state?.idQuestionnaire).then(item => setSurveyInfos(item))
		// Getting data content
		donnees(page)
			.then((e) => {
				setPageContent(e.contents)
			})
			.catch((err) => setMessage(JSON.stringify(err.message)))
			.then(() => {
				setLoading(false);
			});
	}, []);

	const navigateToQuestionnaireOrIntro = () => {
		history.push({
			pathname:
				props.location.state.nextQuestion != null && props.location.state.nextQuestion !== 0 ? 'questionnaire' : '/intro',
			state: {
				idQuestionnaire: props.location.state.idQuestionnaire,
				idQuestionHasUser: props.location.state.idQuestionHasUser,
				nextQuestion: props.location.state.nextQuestion,
				feuilleRoute: props.location.state.feuilleRoute,
			},
		})
	}

	return (
		<Grid container className="fondEdito">
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Menu iconColor="white" />
					</Grid>
				</Grid>
				{loading ? (
					<Modal>
						<ProgressSpinner></ProgressSpinner>
					</Modal>
				) : (
						<Grid container className="editoContainer">
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" justify="center" alignItems="center">
									<Grid item sm={8} md={8} lg={8} xl={8} className={'text'}>
										<h1 style={theme2.typography.h1}>{ReactHtmlParser(pageContent.titre?.text)}</h1>
										<p className={'text-wrapper'}>{ReactHtmlParser(getCorpsText())}</p>
										{props.location.state.nextQuestion ?
											<p className={'surveyResumeText'}>Vous allez maintenant pouvoir poursuivre ce questionnaire 360.</p>
											: null
										}
									</Grid>
								</Grid>
								<Grid container direction="row" justify="center" alignItems="center" className="boutonCentrer">
									<Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
										<BoutonHaxio libelle={ReactHtmlParser(props.location.state.nextQuestion ? pageContent.button?.textResume : pageContent.button?.text)} action={navigateToQuestionnaireOrIntro} couleur="primary" />
									</Grid>
								</Grid>
								<ErrorAlert errorMessage={errorMessage} />
							</Grid>
						</Grid>
					)}
			</Grid>
			<Footer />
		</Grid>
	);
};

export default Edito;
