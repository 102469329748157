import React, { useState } from 'react';
import './ResetPassword.page.scss';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { BoutonBasic } from '../../components/ui/Boutons.component';
import Hidden from '@material-ui/core/Hidden';
import { theme } from '../../theme/theme';
import axios from 'axios'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom'


function ResetPassword(props) {
	const [errorMessage, setErrorMessage] = useState('');
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [token, setToken] = useState('');
	const history = useHistory()


	useEffect(() => {
		let params = new URLSearchParams(window.location.search);
		setToken(params.get('token'))
	}, [])



	const sendReset = async () => {
		if (password != '' && newPassword != '' & password == newPassword) {
			let localToken = ''
			if (token) {
				localToken = token.split('_')[0]
			}

			await axios.put(`${process.env.REACT_APP_API_URL2}users/updatePassword`,
				{ token: token, password: password },
				{ headers: { 'x-access-token': `${localToken}` } }
			);
			await setErrorMessage('Validé')
			await history.push('/login')
		} else {
			setErrorMessage('Les mots de passes doivent etre identique')
		}
	}

	return (
		<Grid container item>
			<Hidden smDown>
				<Grid container item md={6} lg={6} xl={6}>
					<Grid item className="fondGaucheDesktop"></Grid>
				</Grid>
			</Hidden>
			<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="fondDroite">

				<div className="form-signin resetPassword-wrapper">
					<img className="logo" alt="logo Haxio" src="images/logo-haxio.png"></img>
					<div className="resetPassword-wrapper-text">
						<h4 style={theme.typography.h4}>Réinitialisation de votre mot de passe</h4>
						<p>Nouveau mot de passe</p>
						<input type="password" name="password" value={password} onChange={(e) => { setPassword(e.target.value); setErrorMessage('') }} />
						<p>Confirmation du mot de passe</p>
						<input type="password" name="newPassword" value={newPassword} onChange={(e) => { setNewPassword(e.target.value); setErrorMessage('') }} />
						<a>{errorMessage}</a>

					</div>
					<div className="boutonCentrer" onClick={() => sendReset()} >
						<BoutonBasic libelle="Valider" couleur="primary" />
					</div>

				</div>

				<span className={'versionNumber'}>{process.env.REACT_APP_VERSION}</span>
			</Grid>
		</Grid >
	);
}

export default withRouter(ResetPassword);
